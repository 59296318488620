





















import { Component, Vue } from 'vue-property-decorator';
import MenuBar from '@/components/MenuBar.vue';
import VFooter from '@/components/VFooter.vue';

@Component({
  components: {
    MenuBar,
    VFooter,
  },
})
export default class App extends Vue {}
