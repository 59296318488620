import { RootState } from '../types';
import { ActionTree, ActionContext } from 'vuex';
import { AccountState, InlenerCao, InlenerCaoDetail } from './types';
import { AxiosError } from 'axios';
import axios from 'axios';
import { apiUrl, adapter } from '../adapter';
import { userInfo } from 'os';

const snakeToCamelCase = (snake: string): string => {
   return snake.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const responseToCamelCase = (response: { [key: string]: string }) => {
  const camelResponse: { [key: string]: string } = {};
  Object.keys(response).forEach((key) => {
    camelResponse[snakeToCamelCase(key)] = response[key];
  });
  return camelResponse;
};

export const actions: ActionTree<AccountState, RootState> = {

  register(store: ActionContext<AccountState, RootState>, payload:
    {
      email: string, password: string, company: string, firstName: string, lastName: string, nbbuNumber: string,
    }): Promise<{ key: string }> {
      return adapter.postRequest('/rest-auth/registration/', {
        username: payload.email,
        password1: payload.password,
        password2: payload.password,
        email: payload.email,
        first_name: payload.firstName,
        last_name: payload.lastName,
        company_name: payload.company,
        kvk_nr: '',
        iban_nr: '',
        iban_name: '',
        terms_and_conditions_accepted: true,
        incasso_accepted: false,
        company_user: true,
        subscription_type: 'trial',
        nbbu_number: payload.nbbuNumber,
      }, undefined, { withCredentials: true });
  },

  profileImage(store: ActionContext<AccountState, RootState>, refresh: boolean = false): Promise<{}> {
    return new Promise((resolve, reject) => {
      if (!refresh && store.state.profileImage !== '') {
        resolve(store.state.profileImage);
      } else {
        adapter.getRequest('/rest-auth/profileImage/', {}, true, {}, {})
        .then((response) => {
          store.commit('SET_PROFILE_IMAGE', 'data:image/png;base64, ' + response);
          resolve('data:image/png;base64, ' + response);
        });
      }
    });
  },

  inviteInlenerUser(store: ActionContext<AccountState, RootState>, payload:
    { email: string }): Promise<Array<{sent_at: string; sent_to_email: string }>> {
    return adapter.postRequest('/inlener/inlener-invites/', payload, true);
  },

  inlenerInvites(store, payload): Promise<Array<{sent_at: string; sent_to_email: string }>> {
    return adapter.getRequest('/inlener/inlener-invites/', payload, true);
  },

  inlenerCaos(
      store: ActionContext<AccountState, RootState>,
      payload: { page: number, contains: string, only_user: boolean },
  ): Promise<{ caos: InlenerCao[], page: number }> {
       return new Promise((resolve, reject) => {
        adapter.getRequest('/inlener/inleners-cao-uitzender-list/', payload, true)
        .then((response: { caos: InlenerCao[], page: number }) => {
          resolve(response);
        })
        .catch(reject);
      });
  },

  inlenerCaoDetail(store: ActionContext<AccountState, RootState>, inlenerCaoId: number): Promise<InlenerCaoDetail[]> {
       return new Promise((resolve, reject) => {
        adapter.getRequest('/inlener/inleners-cao-detail/', { inlenerCaoId: inlenerCaoId.toString() }, true)
        .then((response: InlenerCaoDetail[]) => {
          resolve(response);
        }).catch(() => reject());
      });
  },

  inlenerCaoVersions(store: ActionContext<AccountState, RootState>, inlenerCaoGuid: string): Promise<InlenerCao[]> {
       return new Promise((resolve, reject) => {
        adapter.getRequest(`/inlener/inleners-cao-uitzender-versions/${inlenerCaoGuid}/`, {}, true)
        .then((response: InlenerCao[]) => {
          resolve(response);
        }).catch(() => reject());
      });
  },

  uploadImage(store: ActionContext<AccountState, RootState>, payload: FormData): Promise<{}> {
    return adapter.postRequest('/rest-auth/profileImage/', payload, true, {},
    { 'Content-type': 'multipart/form-data' });
  },

  userInfo(store: ActionContext<AccountState, RootState>): Promise<{}> {
      return new Promise((resolve, reject) => {
        adapter.getRequest('/rest-auth/user-detail/', {}, true).then((response: { [key: string]: string }) => {
          const cmc = responseToCamelCase(response);
          store.commit('SET_USER_INFO', cmc);
          resolve(cmc);
        });
      });
  },

  updateUserInfo(store: ActionContext<AccountState, RootState>, payload:
    { firstName: string, lastName: string }): Promise<{}> {
    return new Promise((resolve, reject) => {
      axios.patch(apiUrl + '/rest-auth/user/', {
        first_name: payload.firstName,
        last_name: payload.lastName,
      }, {
        withCredentials: false, headers: {
        'Authorization': 'Token ' + store.state.token,
        'Content-type': 'application/json',
        },
      }).then((response: any) => {
        store.dispatch('userInfo');
        resolve({});
      }).catch((error: AxiosError) => {
        reject(error);
      });
    });
  },

  subscriptionInfo(store: ActionContext<AccountState, RootState>): Promise<{}> {
    return new Promise((resolve, reject) => {
      adapter.getRequest('/rest-auth/subscription/', {},
      true).then((responseData: any) => {
        const cmc = responseToCamelCase(responseData);
        store.commit('SET_SUBSCRIPTION_INFO', cmc);
        resolve(cmc);
      }).catch((error: AxiosError) => {
        reject(error);
      });
    });
  },

  updateSubscriptionInfo(
    store: ActionContext<AccountState, RootState>,
    payload: {
      companyName: string,
      ibanName: string,
      ibanNr: string,
      incassoAccepted: boolean,
      kvkNr: number,
      type: string,
      inlenerWebModule: boolean,
    }): Promise<{}> {
    return new Promise((resolve, reject) => {
      axios.patch(apiUrl + '/rest-auth/subscription/', {
        company_name: payload.companyName,
        iban_name: payload.ibanName,
        iban_nr: payload.ibanNr,
        incasso_accepted: payload.incassoAccepted,
        kvk_nr: payload.kvkNr,
        type: payload.type,
        inlener_web_module: payload.inlenerWebModule,
      }, {
        withCredentials: false, headers: {
        'Authorization': 'Token ' + store.state.token,
        'Content-type': 'application/json',
        },
      }).then((response: any) => {
        const cmc = responseToCamelCase(response.data);
        store.commit('SET_SUBSCRIPTION_INFO', cmc);
        resolve();
      }).catch((error: AxiosError) => {
        reject(error);
      });
    });
  },

  addSubscriptionUser(store: ActionContext<AccountState, RootState>, payload:
    { email: string, username: string }): Promise<any> {
    return adapter.postRequest('/rest-auth/subscriptionUserAdd/', payload, true);
  },

  deleteSubscriptionUser(store: ActionContext<AccountState, RootState>, payload:
    { email: string }): Promise<{}> {
    return new Promise((resolve, reject) => {
      axios.delete(apiUrl + '/rest-auth/subscription-user-delete/', {
        data: payload,
        withCredentials: false, headers: {
          'Authorization': 'Token ' + store.state.token,
          'Content-type': 'application/json',
        },
      })
      .then(resolve)
      .catch(reject);
    });
  },

  subscriptionUsers(store: ActionContext<AccountState, RootState>, payload:
    { password: string, newPassword: string }): Promise<{}> {
    return new Promise((resolve, reject) => {
      adapter.getRequest('/rest-auth/subscription-users/', {}, true).then((response) => {
        resolve(responseToCamelCase(response));
      });
    });
  },

  changePassword(store: ActionContext<AccountState, RootState>, payload:
    { newPassword: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      adapter.postRequest('/rest-auth/password/change/',
      { new_password1: payload.newPassword, new_password2: payload.newPassword },
      true)
      .then(() => {
          store.commit('LOGOUT');
          resolve();
      }).catch((error: AxiosError) => {
        reject(error.response);
      });
    });
  },

  resetPassword(
    store: ActionContext<AccountState, RootState>,
    payload: { token: string, uidb64: string, password: string }): Promise<{}> {
    return adapter.postRequest('/rest-auth/reset-password/', payload, false);
  },

  forgotPassword(store: ActionContext<AccountState, RootState>, email): Promise<any> {
    return adapter.postRequest('/rest-auth/forgot-password/', { email });
  },

  /**
   * Verify the email address of a registration
   * @param store
   * @param verifyToken the token from the email
   */
  verify(
    store: ActionContext<AccountState, RootState>,
    payload: {
      token: string,
      uidb64: string,
      password?: string,
      firstName?: string,
      lastName?: string,
    }): Promise<{}> {
    return adapter.postRequest('/rest-auth/activation/', {
      first_name: payload.firstName,
      last_name: payload.lastName,
      token: payload.token,
      uidb64: payload.uidb64,
      password: payload.password,
    }, false);
  },

  login(store: ActionContext<AccountState, RootState>, payload: { username: string, password: string }): Promise<{}> {
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/rest-auth/web-app-login/', {
        username: payload.username,
        password: payload.password,
      }, { withCredentials: false, headers: {
      } })
        .then((response: any) => {
          store.commit('LOGIN',
          {
            token: (response.data as any).key,
            userName: payload.username,
          });
          store.dispatch('userInfo');
          store.dispatch('subscriptionInfo');
          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
  },
};
