











































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import * as types from '@/store/main/types';
import VButton from '@/components/VButton.vue';

@Component({ components: { VButton } })
export default class MenuBar extends Vue {
  @Getter('isLoggedIn', { namespace: 'account' }) private isLoggedIn!: boolean;
  @Mutation('LOGOUT', { namespace: 'account' }) private logout!: () => void;

  private showMobileDropdown = false;

  private handleLogout() {
    this.logout();
    this.$router.push({ name: 'login' });
  }

  private handleScroll(evt: Event, el: HTMLElement) {
    if (window.scrollY > 100) {
      el.classList.add('open');
    } else {
      el.classList.remove('open');
    }
  }
}
